import { useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

// ----------------------------------------------------------------------

export default function Fingerprint() {
  // When you need the visitor identifier:
  const fpPromise = FingerprintJS.load({ token: '4DtKPougr89YjdvtoS7m', region: 'eu' });

  useEffect(() => {
    if (userString === '')
      fpPromise
        .then((fp) => fp.get({ extendedResult: true }))
        .then((result) => {
          setUserString(JSON.stringify(result));
        });
  });

  const [userString, setUserString] = useState('');

  return <code>{userString}</code>;
}
